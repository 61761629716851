(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.editorSkinsData = f()}})(function(){var define,module,exports;
/* Autogenerated file. Do not modify */
'use strict';
var skins = {};
 skins['skins.viewer.bgImage.bgImageSkin'] = {
  "react": [],
  "css": {}
}
 skins['skins.viewer.bgMedia.bgMediaSkin'] = {
  "react": [],
  "css": {}
}
 skins['skins.viewer.bgOverlay.bgOverlaySkin'] = {
  "react": [
    [
      "div",
      "overlay",
      [],
      {}
    ]
  ]
}
 skins['skins.viewer.bgVideo.html5VideoSkin'] = {
  "react": [
    [
      "video",
      "video",
      [],
      {
        "role": "presentation"
      }
    ],
    [
      "div",
      "poster",
      [],
      {}
    ]
  ],
  "exports": {
    "poster": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "css": {}
}
 skins['skins.viewer.bgVideo.iframeVideoSkin'] = {
  "react": [
    [
      "div",
      "video",
      [],
      {}
    ],
    [
      "div",
      "poster",
      [],
      {}
    ]
  ],
  "exports": {
    "poster": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "css": {}
}
 skins['skins.viewer.bgVideo.webglMediaSkin'] = {
  "react": [
    [
      "canvas",
      "canvas",
      [],
      {}
    ]
  ],
  "css": {}
}

return skins;

});
